/* global KTApp, swal, KTUtil,FormValidation */

export let LoginClass = function () {

    let _login;
    let form;
    let submitButton;
    let validator;

    let _loadingBlockPage = function(){

        KTUtil.onDOMContentLoaded(function() {
            KTUtil.removeClass(_login,'overlay-block');
        });
    };

    let _showForm = function(form) {
        let cls = 'login-' + form + '-on';
        let form_element = 'login_' + form + '_form';

        KTUtil.removeClass(_login,'login-forgot-on');
        KTUtil.removeClass(_login,'login-signin-on');
        KTUtil.removeClass(_login,'login-signup-on');

        KTUtil.addClass(cls);

        KTUtil.animateClass(KTUtil.getById(form_element), 'animate__animated animate__backInUp');
    };

    // Handle form
    let _handleSignInForm = function(e) {
        // Init form validation rules. For more info check the FormValidation plugin's official documentation:https://formvalidation.io/
        validator = FormValidation.formValidation(
            form,
            {
                fields: {
                    'usrnm': {
                        validators: {
                            notEmpty: {
                                message: 'Email διεύθυνση είναι υποχρεωτική'
                            },
                            emailAddress: {
                                message: 'Email διεύθυνση δεν είναι έγκυρη'
                            }
                        }
                    },
                    'pass': {
                        validators: {
                            notEmpty: {
                                message: 'The password is required'
                            },
                            callback: {
                                message: 'Please enter valid password',
                                callback: function(input) {
                                    if (input.value.length > 0) {
                                        return _validatePassword();
                                    }
                                }
                            }
                        }
                    }
                },
                plugins: {
                    trigger: new FormValidation.plugins.Trigger(),
                    bootstrap: new FormValidation.plugins.Bootstrap5({
                        rowSelector: '.fv-row',
                        eleInvalidClass: '',
                        eleValidClass: ''
                    })
                }
            }
        );

        // Handle form submit
        submitButton.addEventListener('click', function (e) {
            // Prevent button default action
            e.preventDefault();

            // Validate form
            validator.validate().then(function (status) {
                if (status === 'Valid') {
                    // Show loading indication
                    submitButton.setAttribute('data-kt-indicator', 'on');

                    // Disable button to avoid multiple click
                    submitButton.disabled = true;

                    KTUtil.scrollTop();
                    document.getElementById('login_signin_form').submit();
                    _login.addClass('overlay-block');

                } else {
                    // Show error popup. For more info check the plugin's official documentation: https://sweetalert2.github.io/
                    Swal.fire({
                        text: "Sorry, looks like there are some errors detected, please try again.",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn btn-primary"
                        }
                    });
                }
            });
        });
    };

    // Password input validation
    let _validatePassword = function() {
        return  (passwordMeter.getScore() === 100);
    };


    let _handleForgotForm = function() {

        let validation;

        // Init form validation rules. For more info check the FormValidation plugin's official documentation:https://formvalidation.io/
        validation = FormValidation.formValidation(
            KTUtil.getById('login_forgot_form'),
            {
                fields: {
                    email: {
                        validators: {
                            notEmpty: {
                                message: 'Email address is required'
                            },
                            emailAddress: {
                                message: 'The value is not a valid email address'
                            }
                        }
                    }
                },
                plugins: {
                    trigger: new FormValidation.plugins.Trigger(),
                    bootstrap: new FormValidation.plugins.Bootstrap()
                }
            }
        );

        // Handle submit button
        $('#login_forgot_submit').on('click', function (e) {
            e.preventDefault();

            validation.validate().then(function(status) {
                if (status === 'Valid') {
                    // Submit form
                    KTUtil.scrollTop();
                } else {
                    swal.fire({
                        text: "Sorry, looks like there are some errors detected, please try again.",
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok, got it!",
                        customClass: {
                            confirmButton: "btn font-weight-bold btn-light-primary"
                        }
                    }).then(function() {
                        KTUtil.scrollTop();
                    });
                }
            });
        });

        // Handle cancel button
        $('#login_forgot_cancel').on('click', function (e) {
            e.preventDefault();

            _showForm('#kt_sign_in_form');
        });
    };

    // Public Functions
    return {
        // public functions
        init: function() {
            _login = document.querySelector('#login_wrapper');
            form = document.querySelector('#login_signin_form');
            submitButton = document.querySelector('#kt_sign_in_submit');
            _handleSignInForm();
          //  _handleForgotForm();
            KTApp.initPageLoader();
        }
    };
}();